import * as React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Icon from './Icon'

export default function Skill({ iconName, label }) {
  return (
    <Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={6}
        sm={4}
      >
        <Icon name={iconName} />
        <Typography align="center" variant="overline">
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}
