export default function Ruby() {
  return (
    <svg viewBox="0 0 128 128">
      <linearGradient
        id="ruby-original-a"
        gradientUnits="userSpaceOnUse"
        x1="157.08"
        y1="2382.05"
        x2="131.682"
        y2="2426.892"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#FB7655"></stop>
        <stop offset="0" stopColor="#FB7655"></stop>
        <stop offset=".41" stopColor="#E42B1E"></stop>
        <stop offset=".99" stopColor="#900"></stop>
        <stop offset="1" stopColor="#900"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-a)"
        d="M97.078 83.214L28.34 124.031l89.003-6.04 6.855-89.745z"
      ></path>
      <linearGradient
        id="ruby-original-b"
        gradientUnits="userSpaceOnUse"
        x1="169.731"
        y1="2419.72"
        x2="136.998"
        y2="2441.685"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#871101"></stop>
        <stop offset="0" stopColor="#871101"></stop>
        <stop offset=".99" stopColor="#911209"></stop>
        <stop offset="1" stopColor="#911209"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-b)"
        d="M117.488 117.93l-7.649-52.799-20.837 27.514z"
      ></path>
      <linearGradient
        id="ruby-original-c"
        gradientUnits="userSpaceOnUse"
        x1="143.542"
        y1="2380.69"
        x2="110.81"
        y2="2402.655"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#871101"></stop>
        <stop offset="0" stopColor="#871101"></stop>
        <stop offset=".99" stopColor="#911209"></stop>
        <stop offset="1" stopColor="#911209"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-c)"
        d="M117.592 117.93l-56.044-4.399-32.91 10.385z"
      ></path>
      <linearGradient
        id="ruby-original-d"
        gradientUnits="userSpaceOnUse"
        x1="74.817"
        y1="2435.622"
        x2="79.891"
        y2="2402.644"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset=".23" stopColor="#E57252"></stop>
        <stop offset=".46" stopColor="#DE3B20"></stop>
        <stop offset=".99" stopColor="#A60003"></stop>
        <stop offset="1" stopColor="#A60003"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-d)"
        d="M28.717 123.928l14.001-45.867-30.81 6.588z"
      ></path>
      <linearGradient
        id="ruby-original-e"
        gradientUnits="userSpaceOnUse"
        x1="109.719"
        y1="2466.413"
        x2="111.589"
        y2="2432.757"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset=".23" stopColor="#E4714E"></stop>
        <stop offset=".56" stopColor="#BE1A0D"></stop>
        <stop offset=".99" stopColor="#A80D00"></stop>
        <stop offset="1" stopColor="#A80D00"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-e)"
        d="M88.996 92.797l-12.882-50.46-36.866 34.558z"
      ></path>
      <linearGradient
        id="ruby-original-f"
        gradientUnits="userSpaceOnUse"
        x1="140.691"
        y1="2497.523"
        x2="146.289"
        y2="2473.401"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset=".18" stopColor="#E46342"></stop>
        <stop offset=".4" stopColor="#C82410"></stop>
        <stop offset=".99" stopColor="#A80D00"></stop>
        <stop offset="1" stopColor="#A80D00"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-f)"
        d="M121.275 43.047L86.426 14.585l-9.704 31.373z"
      ></path>
      <linearGradient
        id="ruby-original-g"
        gradientUnits="userSpaceOnUse"
        x1="123.6"
        y1="2506.018"
        x2="147.719"
        y2="2518.077"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset=".54" stopColor="#C81F11"></stop>
        <stop offset=".99" stopColor="#BF0905"></stop>
        <stop offset="1" stopColor="#BF0905"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-g)"
        d="M104.978 4.437L84.481 15.764 71.551 4.285z"
      ></path>
      <linearGradient
        id="ruby-original-h"
        gradientUnits="userSpaceOnUse"
        x1="53.674"
        y1="2444.028"
        x2="55.66"
        y2="2424.153"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset="0" stopColor="#fff"></stop>
        <stop offset=".31" stopColor="#DE4024"></stop>
        <stop offset=".99" stopColor="#BF190B"></stop>
        <stop offset="1" stopColor="#BF190B"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-h)"
        d="M3.802 100.034l8.586-15.659L5.442 65.72z"
      ></path>
      <path
        fill="#fff"
        d="M4.981 65.131l6.987 19.821 30.365-6.812L77 45.922l9.783-31.075L71.38 3.969l-26.19 9.802c-8.252 7.675-24.263 22.86-24.84 23.146-.573.291-10.575 19.195-15.369 28.214z"
      ></path>
      <linearGradient
        id="ruby-original-i"
        gradientUnits="userSpaceOnUse"
        x1="40.026"
        y1="2418.781"
        x2="133.345"
        y2="2514.739"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#BD0012"></stop>
        <stop offset="0" stopColor="#BD0012"></stop>
        <stop offset=".07" stopColor="#fff"></stop>
        <stop offset=".17" stopColor="#fff"></stop>
        <stop offset=".27" stopColor="#C82F1C"></stop>
        <stop offset=".33" stopColor="#820C01"></stop>
        <stop offset=".46" stopColor="#A31601"></stop>
        <stop offset=".72" stopColor="#B31301"></stop>
        <stop offset=".99" stopColor="#E82609"></stop>
        <stop offset="1" stopColor="#E82609"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-i)"
        d="M29.519 29.521c17.882-17.73 40.937-28.207 49.785-19.28 8.843 8.926-.534 30.62-18.418 48.345-17.884 17.725-40.653 28.779-49.493 19.852-8.849-8.92.242-31.191 18.126-48.917z"
      ></path>
      <linearGradient
        id="ruby-original-j"
        gradientUnits="userSpaceOnUse"
        x1="111.507"
        y1="2409.102"
        x2="83.398"
        y2="2416.039"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#8C0C01"></stop>
        <stop offset="0" stopColor="#8C0C01"></stop>
        <stop offset=".54" stopColor="#990C00"></stop>
        <stop offset=".99" stopColor="#A80D0E"></stop>
        <stop offset="1" stopColor="#A80D0E"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-j)"
        d="M28.717 123.909l13.89-46.012 46.135 14.82c-16.68 15.642-35.233 28.865-60.025 31.192z"
      ></path>
      <linearGradient
        id="ruby-original-k"
        gradientUnits="userSpaceOnUse"
        x1="159.785"
        y1="2442.837"
        x2="134.814"
        y2="2465.217"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#7E110B"></stop>
        <stop offset="0" stopColor="#7E110B"></stop>
        <stop offset=".99" stopColor="#9E0C00"></stop>
        <stop offset="1" stopColor="#9E0C00"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-k)"
        d="M77.062 45.831l11.844 46.911c13.934-14.65 26.439-30.401 32.563-49.883l-44.407 2.972z"
      ></path>
      <linearGradient
        id="ruby-original-l"
        gradientUnits="userSpaceOnUse"
        x1="168.959"
        y1="2483.901"
        x2="156.521"
        y2="2497.199"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#79130D"></stop>
        <stop offset="0" stopColor="#79130D"></stop>
        <stop offset=".99" stopColor="#9E120B"></stop>
        <stop offset="1" stopColor="#9E120B"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-l)"
        d="M121.348 43.097c4.74-14.305 5.833-34.825-16.517-38.635l-18.339 10.13 34.856 28.505z"
      ></path>
      <path
        fill="#9E1209"
        d="M3.802 99.828c.656 23.608 17.689 23.959 24.945 24.167l-16.759-39.14-8.186 14.973z"
      ></path>
      <radialGradient
        id="ruby-original-m"
        cx="138.703"
        cy="2464.789"
        r="30.601"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#A80D00"></stop>
        <stop offset="0" stopColor="#A80D00"></stop>
        <stop offset=".99" stopColor="#7E0E08"></stop>
        <stop offset="1" stopColor="#7E0E08"></stop>
      </radialGradient>
      <path
        fill="url(#ruby-original-m)"
        d="M77.128 45.904c10.708 6.581 32.286 19.798 32.723 20.041.68.383 9.304-14.542 11.261-22.976l-43.984 2.935z"
      ></path>
      <radialGradient
        id="ruby-original-n"
        cx="96.325"
        cy="2424.465"
        r="40.679"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#A30C00"></stop>
        <stop offset="0" stopColor="#A30C00"></stop>
        <stop offset=".99" stopColor="#800E08"></stop>
        <stop offset="1" stopColor="#800E08"></stop>
      </radialGradient>
      <path
        fill="url(#ruby-original-n)"
        d="M42.589 77.897l18.57 35.828c10.98-5.955 19.579-13.211 27.454-20.983L42.589 77.897z"
      ></path>
      <linearGradient
        id="ruby-original-o"
        gradientUnits="userSpaceOnUse"
        x1="67.509"
        y1="2393.115"
        x2="57.373"
        y2="2427.506"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#8B2114"></stop>
        <stop offset="0" stopColor="#8B2114"></stop>
        <stop offset=".43" stopColor="#9E100A"></stop>
        <stop offset=".99" stopColor="#B3100C"></stop>
        <stop offset="1" stopColor="#B3100C"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-o)"
        d="M11.914 84.904l-2.631 31.331c4.964 6.781 11.794 7.371 18.96 6.842-5.184-12.9-15.538-38.696-16.329-38.173z"
      ></path>
      <linearGradient
        id="ruby-original-p"
        gradientUnits="userSpaceOnUse"
        x1="145.272"
        y1="2507.076"
        x2="167.996"
        y2="2497.045"
        gradientTransform="matrix(1 0 0 -1 -47.5 2517)"
      >
        <stop offset="0" stopColor="#B31000"></stop>
        <stop offset="0" stopColor="#B31000"></stop>
        <stop offset=".44" stopColor="#910F08"></stop>
        <stop offset=".99" stopColor="#791C12"></stop>
        <stop offset="1" stopColor="#791C12"></stop>
      </linearGradient>
      <path
        fill="url(#ruby-original-p)"
        d="M86.384 14.67l36.891 5.177c-1.969-8.343-8.015-13.727-18.32-15.41L86.384 14.67z"
      ></path>
    </svg>
  )
}
