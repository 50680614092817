import * as React from 'react'
import Typography from '@mui/material/Typography'
import Skill from './Skill'

export default function Skills() {
  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h4" align="center">
          Top Skills
        </Typography>
      </div>
      <Skill iconName="react" label="React" />
      <Skill iconName="js" label="JavaScript" />
      <Skill iconName="ts" label="TypeScript" />
      <Skill iconName="node" label="Node.js" />
      <Skill iconName="ruby" label="Ruby" />
      <Skill iconName="python" label="Python" />
      <Skill iconName="html" label="HTML" />
      <Skill iconName="css" label="CSS" />
      <Skill iconName="redux" label="Redux" />
      <Skill iconName="d3" label="D3.js" />
      <Skill iconName="mongo" label="MongoDB" />
      <Skill iconName="postgres" label="PostgreSQL" />
    </>
  )
}
