import * as React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Icon from './Icon'

export default function Contact() {
  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h4" align="center">
          Get in touch
        </Typography>
      </div>
      <Grid container spacing={5}>
        <Grid
          xs={12}
          sm={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <a
            href="https://github.com/mollyfhanes"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="github" />
          </a>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <a
            href="https://linkedin.com/in/mollyfhanes/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="linkedin" />
          </a>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <a href="mailto:hello@mollyfhanes.com">
            <Icon name="mail" />
          </a>
        </Grid>
      </Grid>
    </>
  )
}
