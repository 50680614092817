import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import avatar from './avatar.png'
import MobileMenuButton from './MobileMenuButton'

export const SECTION_TITLES = ['About', 'Skills', 'Contact']

export default function Menu({ setValue }) {
  return (
    <AppBar position="static">
      <Grid container>
        <Grid item xs={10}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setValue(0)
              }}
            >
              <img
                src={avatar}
                alt="software engineer"
                style={{ height: '5vh' }}
              />
            </IconButton>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {SECTION_TITLES.map((title, i) => (
                <MenuItem onClick={() => setValue(i + 1)}>
                  <Typography>{title}</Typography>
                </MenuItem>
              ))}
              <MenuItem>
                <Link
                  href="https://docs.google.com/document/d/1G6CaRS6AVQJgh6IdYgK70zRA8TN6wT_HVLTvkgMmlp8/edit?usp=sharing"
                  target="_blank"
                  color="inherit"
                  underline="none"
                >
                  Resume
                </Link>
              </MenuItem>
            </Box>
          </Toolbar>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ display: { xs: 'flex', md: 'none' }, paddingTop: '12px' }}
        >
          <MobileMenuButton setValue={setValue} />
        </Grid>
      </Grid>
    </AppBar>
  )
}
