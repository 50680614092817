import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Icon from './Icon'
import { SECTION_TITLES } from './Menu'

export default function MobileMenuButton({ setValue }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id="positioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon name="menu" height="35px" width="35px" />
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {SECTION_TITLES.map((title, i) => (
          <MenuItem
            onClick={() => {
              setValue(i + 1)
              handleClose()
            }}
          >
            <Typography>{title}</Typography>
          </MenuItem>
        ))}
        <MenuItem>
          <Link
            href="https://docs.google.com/document/d/1G6CaRS6AVQJgh6IdYgK70zRA8TN6wT_HVLTvkgMmlp8/edit?usp=sharing"
            target="_blank"
            color="inherit"
            underline="none"
          >
            Resume
          </Link>
        </MenuItem>
      </Menu>
    </>
  )
}
