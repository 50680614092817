import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Home from './Home'
import Skills from './Skills'
import About from './About'
import Contact from './Contact'
import Menu from './Menu'

function App() {
  const [value, setValue] = React.useState(0)

  return (
    <Box sx={{ width: '100%' }}>
      <Menu setValue={setValue} />
      <Grid
        container
        spacing={5}
        xs
        sx={{
          marginTop: '5vh',
          paddingX: '5vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {value === 0 && <Home />}
        {value === 1 && <About />}
        {value === 2 && <Skills />}
        {value === 3 && <Contact />}
      </Grid>
    </Box>
  )
}

export default App
