import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

export default function About() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          About me
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          I've been a professional software engineer since 2018. I first taught
          myself to code as a kid, and it became a lifelong hobby that
          eventually turned into a career. Most recently, I've worked for Amazon
          Web Services and Stripe.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          In my past life, I was a classically trained opera singer and voice
          teacher. In my free time, I enjoy performing improv comedy, playing
          RPGs, and watching horror movies with my husband and our two rescue
          dogs, Lois and Gooby.
        </Typography>
      </Grid>
    </Grid>
  )
}
