export default function MenuIcon() {
  return (
    <svg fill="#FFFFFF" viewBox="0 0 128 128">
      <g>
        <g>
          <rect x="1.134" width="90.463" height="14.214" />
          <rect x="1.186" y="25.721" width="90.357" height="14.215" />
          <rect x="1.134" y="52.795" width="90.463" height="14.215" />
          <rect x="1.186" y="78.518" width="90.357" height="14.214" />
        </g>
      </g>
    </svg>
  )
}
